import React from 'react';
import Autoplay from 'embla-carousel-autoplay';
import useEmblaCarousel, { EmblaOptionsType } from 'embla-carousel-react';

import { cn } from '@/shared/lib/cn';

import { Button } from '../ui/button';
import { slidesClass } from './const';

interface CarouselProps {
  children: React.ReactNode;
  aspectRatio?: number;
  options?: EmblaOptionsType;
  slidesToScroll?: EmblaOptionsType['slidesToScroll'];
}

export const Carousel = (props: CarouselProps) => {
  const { children, aspectRatio = 16 / 9, slidesToScroll = 1, options } = props;

  const [emblaRef, emblaApi] = useEmblaCarousel(
    {
      duration: 20,
      loop: true,
      ...options,
      slidesToScroll,
    },
    [Autoplay({ delay: 8000 })],
  );

  const [_, setSelectedIndex] = React.useState(0);
  const [__, setScrollSnaps] = React.useState<number[]>([]);

  const scrollPrev = React.useCallback(() => emblaApi?.scrollPrev(), [emblaApi]);

  const scrollNext = React.useCallback(() => emblaApi?.scrollNext(), [emblaApi]);

  // const scrollTo = React.useCallback((index: number) => emblaApi && emblaApi.scrollTo(index), [emblaApi]);

  const onSelect = React.useCallback(() => {
    if (!emblaApi) return;
    setSelectedIndex(emblaApi.selectedScrollSnap());
  }, [emblaApi, setSelectedIndex]);

  React.useEffect(() => {
    if (!emblaApi) return;
    onSelect();
    setScrollSnaps(emblaApi.scrollSnapList());
    emblaApi.on('select', onSelect);
    emblaApi.on('reInit', onSelect);
  }, [emblaApi, setScrollSnaps, onSelect]);

  const childElements = React.Children.map(children, (child, key) => (
    <div
      // @ts-expect-error don't accept css vars
      style={{ '--carousel-ratio': aspectRatio }}
      className={cn(
        'relative aspect-[var(--carousel-ratio)] min-w-0 flex-shrink-0 flex-grow-0',
        slidesClass[slidesToScroll] ?? 'auto',
      )}
    >
      {child}
    </div>
  ));

  return (
    <div className="relative">
      <div className="w-full overflow-hidden" ref={emblaRef}>
        <div className="flex">{childElements}</div>
      </div>

      <div className="absolute right-2 top-2 hidden items-center justify-between space-x-2 lg:flex">
        <Button variant="secondary" size="icon" onClick={scrollPrev}>
          {'<'}
        </Button>
        <Button variant="secondary" size="icon" onClick={scrollNext}>
          {'>'}
        </Button>
      </div>
    </div>
  );
};
