import Image from 'next/image';
import { range } from '@effable/misc';

import { MainLayout } from '@/layouts/main-layout';

import { Carousel } from '@/shared/components/carousel';
import { PageSeo } from '@/shared/lib/seo';
import { EnhancedNextPage } from '@/shared/types/enhanced-next-page';

const HomePage: EnhancedNextPage = () => {
  return (
    <>
      <PageSeo title="Главная" />

      <div className="container">
        <Carousel aspectRatio={640 / 425} slidesToScroll={3}>
          {range(8).map((i) => (
            <Image
              key={i}
              src={`/static/banner/banner-${i + 1}.jpg`}
              fill
              alt="banner"
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 75vw, 50vw"
            />
          ))}
        </Carousel>
      </div>

      <section className="container py-8">
        Рады приветствовать ВАС на сайте Marital!
        <br />
        Marital – это российская косметика для поддержания естественной красоты.
        <br />
        Команда технологов, химиков и косметологов разработала уходовые средства для российских женщин с учетом старения
        и строения кожи.
        <br />
        В нашей лаборатории мы проводим исследования с привлечением добровольцев и получаем колоссальные результаты,
        направленные на поддержание молодости и красоты вашей кожи.
        <br />
        Мы искренне надеемся, что вы сможете подобрать полную линейку уходовых средств и по достоинству их оценить.
      </section>
    </>
  );
};

HomePage.Layout = MainLayout;

export default HomePage;
