import { EmblaOptionsType } from 'embla-carousel-react';

export const slidesClass: Record<Required<EmblaOptionsType>['slidesToScroll'], string> = {
  auto: 'basis-full',
  1: 'basis-full',
  2: 'basis-1/2',
  3: 'basis-1/3',
  4: 'basis-1/4',
  5: 'basis-1/5',
  6: 'basis-1/6',
};
